import {Component, OnInit} from '@angular/core';

@Component({
  selector: 'app-404',
  templateUrl: './404.component.html',
  styleUrls: ['./404.component.css']
})
export class NotFoundComponent implements OnInit {

  constructor() {
  }

  ngOnInit() {
  }

  getBack() {
    window.history.back();
  }

}
